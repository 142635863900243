import React, { Fragment } from 'react';

// import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';

import logo from '../../styles/images/logo.png';
import * as CM from '../../components/common/Common';

// Main component for handling render
const LoginHeader = ({ props }) => {
  const history = useHistory();

  // Change top menu state
  const handleClick = async path => {
    history.push(path);
  };

  return (
    <div className="main">
      {/*<div className="top-line" />*/}
      <div className="header" id="gnb">
        <div className="fl logo">
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <div className="fl menu-text ul">
          <LoginTopMenu handleClick={handleClick} />
        </div>
      </div>
    </div>
  );
};

// Top menu component
const LoginTopMenu = props => {
  // const history = useHistory();
  // const handleMovePage = (e, url) => {
  //   e.preventDefault();
  //   history.push(url);
  // };

  return (
    <Fragment>
      <li className="li infoLi" tabIndex="0" style={{ marginLeft: '-174px' }} onClick={e => props.handleClick(e, '/information/information')}>
        빌링원플러스 이용안내
      </li>
      <li className="li" tabIndex="0" onClick={e => CM.cfnAlert('로그인 후 이용하실 수 있습니다.')}>
        고객정보관리
      </li>
      <li className="li" tabIndex="0" onClick={e => CM.cfnAlert('로그인 후 이용하실 수 있습니다.')}>
        수납관리
      </li>
      <li className="li" tabIndex="0" onClick={e => CM.cfnAlert('로그인 후 이용하실 수 있습니다.')}>
        지급관리
      </li>
      <li className="li" tabIndex="0" onClick={e => CM.cfnAlert('로그인 후 이용하실 수 있습니다.')}>
        부가서비스
      </li>
      <li className="li" tabIndex="0" onClick={e => CM.cfnAlert('로그인 후 이용하실 수 있습니다.')}>
        서비스지원
      </li>
    </Fragment>
  );
};

export default LoginHeader;
