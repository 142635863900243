import { Button, Checkbox, FormControlLabel, Grid, Input, Modal, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React, { useLayoutEffect, useState } from "react";
import "../../styles/main.scss";
import * as CM from "../common/Common";
import LoginFooter from "./LoginFooter";
import LoginHeader from "./LoginHeader";

const DISABLE_SMARTCARD = 0x00020000; // 저장토큰 비활성

const LOGIN_SERVLET_URL = "/api/auth/login/yessign";

const CertificateLogin = (props) => {
  //focus 처리
  const loginStore = props.loginStore;
  const inputIntgtLoginId = React.createRef();
  const inputCorporationIntgtLoginId = React.createRef();
  const inputIdentificationNo = React.createRef();
  const inputCorporationIdentificationNo = React.createRef();
  const inputBizNo = React.createRef();
  const inputStaffBirthDate = React.createRef();

  const [open, setOpen] = useState(false);
  const [intgtLoginId, setIntgtLoginId] = useState("");
  const [identificationNo, setIdentificationNo] = useState("");
  const [corporationIntgtLoginId, setCorporationIntgtLoginId] = useState("");
  const [corporationIdentificationNo, setCorporationIdentificationNo] = useState("");
  const [intgtLoginIdCheck, setIntgtLoginIdCheck] = useState(true);
  const [corporationIntgtLoginIdCheck, setCorporationIntgtLoginIdCheck] = useState(true);
  const [intgtLoginIdentificationNoCheck, setIntgtLoginIdentificationNoCheck] = useState(true);
  const [corporationIntgtLoginIdentificationNoCheck, setCorporationIntgtLoginIdentificationNoCheck] = useState(true);
  const [bizNo, setBizNo] = useState("");
  const [staffBirthDate, setStaffBirthDate] = useState("");

  useLayoutEffect(() => {
    if (localStorage.hasOwnProperty("intgtLoginIdCheckId") && localStorage.getItem("intgtLoginIdCheckId") !== "") {
      setIntgtLoginId(localStorage.getItem("intgtLoginIdCheckId"));
    }

    if (localStorage.hasOwnProperty("intgtLoginIdentificationNoCheckId") && localStorage.getItem("intgtLoginIdentificationNoCheckId") !== "") {
      setIdentificationNo(localStorage.getItem("intgtLoginIdentificationNoCheckId"));
    }

    if (localStorage.hasOwnProperty("corporationIntgtLoginIdCheckId") && localStorage.getItem("corporationIntgtLoginIdCheckId") !== "") {
      setCorporationIntgtLoginId(localStorage.getItem("corporationIntgtLoginIdCheckId"));
    }

    if (localStorage.hasOwnProperty("corporationIntgtLoginIdentificationNoCheckId") && localStorage.getItem("corporationIntgtLoginIdentificationNoCheckId") !== "") {
      setCorporationIdentificationNo(localStorage.getItem("corporationIntgtLoginIdentificationNoCheckId"));
    }
  }, []);

  const focusInputLoginId = () => {
    inputIntgtLoginId.current.focus();
  };

  const focusInputCorporationLoginId = () => {
    inputCorporationIntgtLoginId.current.focus();
  };

  const focusInputIdentificationNoLoginId = () => {
    inputIdentificationNo.current.focus();
  };

  const focusInputCorporationIdentificationNoLoginId = () => {
    inputCorporationIdentificationNo.current.focus();
  };

  const focusInputStaffBirthDate = () => {
    inputStaffBirthDate.current.focus();
  };

  const focusInputBizNo = () => {
    inputBizNo.current.focus();
  };

  const signCorporationSubmit = () => {
    if (CM.cfnIsEmpty(corporationIntgtLoginId)) {
      CM.cfnAlert("아이디를 입력해 주세요.", focusInputCorporationLoginId);
      return false;
    }

    if (CM.cfnIsEmpty(corporationIdentificationNo)) {
      CM.cfnAlert("사업자번호를 입력해 주세요.", focusInputCorporationIdentificationNoLoginId);
      return false;
    }

    let dataForm = document.formSignCorporation;
    if (typeof document.formSignCorporation.customerType === "undefined") {
      let input = document.createElement("input");
      input.type = "hidden";
      input.value = "CORPORATION";
      input.name = "customerType";
      input.setAttribute("sign", "on");
      dataForm.appendChild(input);
    }

    let successFunc = onCorporationLoginSuccess;
    let cancelFunc = onLoginCancel;
    let signWtCorporate = window.signWtCorporate;

    signWtCorporate(dataForm, "dummy", null, DISABLE_SMARTCARD, null, successFunc, cancelFunc);
  };

  const signCorporationFinCertSubmit = () => {
    if (CM.cfnIsEmpty(corporationIntgtLoginId)) {
      CM.cfnAlert("아이디를 입력해 주세요.", focusInputCorporationLoginId);
      return false;
    }

    if (CM.cfnIsEmpty(corporationIdentificationNo)) {
      CM.cfnAlert("사업자번호를 입력해 주세요.", focusInputCorporationIdentificationNoLoginId);
      return false;
    }

    let dataForm = document.formSignCorporation;
    if (typeof document.formSignCorporation.customerType === "undefined") {
      let input = document.createElement("input");
      input.type = "hidden";
      input.value = "CORPORATION";
      input.name = "customerType";
      input.setAttribute("sign", "on");
      dataForm.appendChild(input);
    }
    let successFunc = onCorporationLoginSuccess;
    let cancelFunc = onLoginCancel;
    let signWtCorporateFinCert = window.signWtCorporateFinCert;

    signWtCorporateFinCert(dataForm, "dummy", null, DISABLE_SMARTCARD, null, successFunc, cancelFunc);
  }

  const signIndividualSubmit = () => {
    if (CM.cfnIsEmpty(intgtLoginId)) {
      CM.cfnAlert("아이디를 입력해 주세요.", focusInputLoginId);
      return false;
    }

    if (CM.cfnIsEmpty(identificationNo)) {
      CM.cfnAlert("담당자 생년월일을 입력해 주세요.", focusInputIdentificationNoLoginId);
      return false;
    }

    let dataForm = document.formSignIndividual;
    if (typeof document.formSignIndividual.customerType === "undefined") {
      let input = document.createElement("input");
      input.type = "hidden";
      input.value = "INDIVIDUAL";
      input.name = "customerType";
      input.setAttribute("sign", "on");
      dataForm.appendChild(input);
    }
    let successFunc = onIndividualLoginSuccess;
    let cancelFunc = onLoginCancel;
    let signWtPersonal = window.signWtPersonal;

    signWtPersonal(dataForm, null, null, DISABLE_SMARTCARD, null, successFunc, cancelFunc);
  };

  const signIndividualFinCertSubmit = () => {
    if (CM.cfnIsEmpty(intgtLoginId)) {
      CM.cfnAlert("아이디를 입력해 주세요.", focusInputLoginId);
      return false;
    }

    if (CM.cfnIsEmpty(identificationNo)) {
      CM.cfnAlert("담당자 생년월일을 입력해 주세요.", focusInputIdentificationNoLoginId);
      return false;
    }

    let dataForm = document.formSignIndividual;
    if (typeof document.formSignIndividual.customerType === "undefined") {
      let input = document.createElement("input");
      input.type = "hidden";
      input.value = "INDIVIDUAL";
      input.name = "customerType";
      input.setAttribute("sign", "on");
      dataForm.appendChild(input);
    }
    let successFunc = onIndividualLoginSuccess;
    let cancelFunc = onLoginCancel;
    let signWtPersonalFinCert = window.signWtPersonalFinCert;

    signWtPersonalFinCert(dataForm, null, null, DISABLE_SMARTCARD, null, successFunc, cancelFunc);
  };

  const onIndividualLoginSuccess = () => {
    let sendObject = [];
    sendObject.push("customerType=INDIVIDUAL");
    sendObject.push("identificationNo=" + document.formSignIndividual.identificationNo.value);
    sendObject.push("intgtLoginId=" + document.formSignIndividual.intgtLoginId.value);
    if (typeof document.formSignIndividual.__E2E_KEYPAD__ !== "undefined") {
      sendObject.push("__E2E_KEYPAD__=" + document.formSignIndividual.__E2E_KEYPAD__.value);
    }

    if (typeof document.formSignIndividual.__E2E_UNIQUE__ !== "undefined") {
      sendObject.push("__E2E_UNIQUE__=" + document.formSignIndividual.__E2E_UNIQUE__.value);
    }

    if (typeof document.getElementsByName("signedval") !== "undefined") {
      sendObject.push("signedval=" + document.getElementsByName("signedval")[0].value);
    }

    CM.cfnAxiosLogin(LOGIN_SERVLET_URL, "post", sendObject.join("&"), (status, data) => {
      let intgtLoginIdCheck = document.formSignIndividual.intgtLoginIdCheck;
      let intgtLoginIdentificationNoCheck = document.formSignIndividual.intgtLoginIdentificationNoCheck;
      if (intgtLoginIdCheck.checked) {
        localStorage.setItem("intgtLoginIdCheckId", document.formSignIndividual.intgtLoginId.value);
      } else {
        if (localStorage.hasOwnProperty("intgtLoginIdCheckId")) {
          localStorage.removeItem("intgtLoginIdCheckId");
        }
      } //개인 아이디
      if (intgtLoginIdentificationNoCheck.checked) {
        localStorage.setItem("intgtLoginIdentificationNoCheckId", document.formSignIndividual.identificationNo.value);
      } else {
        if (localStorage.hasOwnProperty("intgtLoginIdentificationNoCheckId")) {
          localStorage.removeItem("intgtLoginIdentificationNoCheckId");
        }
      } //개인 생년월일

      loginStore.fnCertificationLoginCallback(status, data);
    });
  };

  const onCorporationLoginSuccess = () => {
    let sendObject = [];
    sendObject.push("customerType=CORPORATION");
    sendObject.push("identificationNo=" + document.formSignCorporation.identificationNo.value);
    sendObject.push("intgtLoginId=" + document.formSignCorporation.intgtLoginId.value);
    if (typeof document.formSignCorporation.__E2E_KEYPAD__ !== "undefined") {
      sendObject.push("__E2E_KEYPAD__=" + document.formSignCorporation.__E2E_KEYPAD__.value);
    }

    if (typeof document.formSignCorporation.__E2E_UNIQUE__ !== "undefined") {
      sendObject.push("__E2E_UNIQUE__=" + document.formSignCorporation.__E2E_UNIQUE__.value);
    }

    if (typeof document.getElementsByName("signedval") !== "undefined") {
      sendObject.push("signedval=" + document.getElementsByName("signedval")[0].value);
    }

    CM.cfnAxiosLogin(LOGIN_SERVLET_URL, "post", sendObject.join("&"), (status, data) => {
      let corporationIntgtLoginIdCheck = document.formSignCorporation.corporationIntgtLoginIdCheck;
      let corporationIntgtLoginIdentificationNoCheck = document.formSignCorporation.corporationIntgtLoginIdentificationNoCheck;
      if (corporationIntgtLoginIdCheck.checked) {
        localStorage.setItem("corporationIntgtLoginIdCheckId", document.formSignCorporation.intgtLoginId.value);
      } else {
        if (localStorage.hasOwnProperty("corporationIntgtLoginIdCheckId")) {
          localStorage.removeItem("corporationIntgtLoginIdCheckId");
        }
      } //기관 아이디
      if (corporationIntgtLoginIdentificationNoCheck.checked) {
        localStorage.setItem("corporationIntgtLoginIdentificationNoCheckId", document.formSignCorporation.identificationNo.value);
      } else {
        if (localStorage.hasOwnProperty("corporationIntgtLoginIdentificationNoCheckId")) {
          localStorage.removeItem("corporationIntgtLoginIdentificationNoCheckId");
        }
      } //기관 사업자번호
      loginStore.fnCertificationLoginCallback(status, data);
    });
  };

  const onLoginCancel = () => {};

  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      signIndividualSubmit();
    }
  };

  const findId = () => {
    if (CM.cfnIsEmpty(bizNo)) {
      CM.cfnAlert("사업자등록번호를 입력해 주세요.", focusInputBizNo);
      return false;
    }

    if (CM.cfnIsEmpty(staffBirthDate)) {
      CM.cfnAlert("담당자 생년월일을 입력해 주세요.", focusInputStaffBirthDate);
      return false;
    }
    const param = [];
    param.push("bizNo=" + bizNo);
    param.push("staffBirthDate=" + staffBirthDate);
    let url = "/api/support/findId?" + param.join("&");
    CM.cfnAxios(url, "get", "", (status, data) => {
      if (status.status !== 200) {
        CM.cfnAlert(data["message"]);
      } else {
        let message = typeof data === "string" ? data : data["message"];
        CM.cfnAlert("입력하신 정보에 해당하는 아이디는 " + message + "입니다.");
      }
    });
  };

  return (
    <div className="wrap">
      <LoginHeader />
      <div className="container">
        <div className="main-root-login">
          <Grid container spacing={3} className="grid" direction="row" justify="center" alignItems="stretch">
            <Grid item xs={5} className="grid-item">
              <div className="logo-login">
                <span className="login-h3 centered">개인인증서로 로그인(업무담당자용)</span>
              </div>
              <div className="login-area">
                <form name="formSignIndividual" id="formSignIndividual" action="api/auth/login/yessign" method="post" className="centered">
                  <div className="id-pw">
                    <label className="label-l">기관아이디</label>
                    <FormControlLabel
                      control={
                        <Input
                          placeholder="아이디를 입력해 주세요"
                          value={intgtLoginId}
                          onChange={(e) => setIntgtLoginId(e.target.value)}
                          inputProps={{
                            sign: "on",
                          }}
                          inputRef={inputIntgtLoginId}
                          name="intgtLoginId"
                          data-testid="test-intgtLoginId"
                          className="input-sytle"
                        />
                      }
                      label=""
                    />
                  </div>
                  <div className="id-pw">
                    <label className="label-l" style={{ paddingRight: "1.5ch" }}>
                      생년월일
                    </label>
                    <FormControlLabel
                      control={
                        <Input
                          placeholder="6자리 숫자를 입력해 주세요"
                          value={identificationNo}
                          onChange={(e) => setIdentificationNo(e.target.value)}
                          inputProps={{
                            sign: "on",
                            maxLength: 6,
                          }}
                          inputRef={inputIdentificationNo}
                          name="identificationNo"
                          data-testid="test-identificationNo"
                          className="input-sytle"
                          onKeyUp={handleSearchKeyUp}
                        />
                      }
                      label=""
                    />
                  </div>
                  <div className="certificateLogin">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="intgtLoginIdCheck"
                          color="primary"
                          checked={intgtLoginIdCheck}
                          onChange={(e) => setIntgtLoginIdCheck(e.target.checked)}
                          inputProps={{
                            "aria-label": "primary checkbox",
                            "data-testid": "check-new-account",
                          }}
                        />
                      }
                      label="아이디저장"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="intgtLoginIdentificationNoCheck"
                          color="primary"
                          checked={intgtLoginIdentificationNoCheck}
                          onChange={(e) => setIntgtLoginIdentificationNoCheck(e.target.checked)}
                          inputProps={{
                            "aria-label": "primary checkbox",
                            "data-testid": "check-new-account",
                          }}
                        />
                      }
                      label="생년월일저장"
                    />
                  </div>
                  <div>
                    <input type="button" className="btn-login btn-triple" onClick={signIndividualSubmit} value="공동인증서 로그인" />
                    <input type="button" className="btn-login btn-triple" onClick={signIndividualFinCertSubmit} value="금융인증서 로그인" />
                    <input type="button" className="btn-findId btn-triple" onClick={() => setOpen(true)} value="아이디찾기" />
                  </div>
                </form>
              </div>
            </Grid>
            <Grid item xs={5} className="grid-item">
              <div className="logo-login">
                <span className="login-h3">사업자인증서로 로그인</span>
              </div>
              <div className="login-area">
                <form name="formSignCorporation" id="formSignCorporation" action="api/auth/login/yessign" method="post" className="centered">
                  <div className="id-pw">
                    <label className="label-l">기관아이디</label>
                    <FormControlLabel
                      control={
                        <Input
                          placeholder="아이디를 입력해 주세요"
                          value={corporationIntgtLoginId}
                          onChange={(e) => setCorporationIntgtLoginId(e.target.value)}
                          inputProps={{
                            sign: "on",
                          }}
                          inputRef={inputCorporationIntgtLoginId}
                          name="intgtLoginId"
                          data-testid="test-corporationIntgtLoginId"
                          className="input-sytle"
                        />
                      }
                      label=""
                    />
                  </div>
                  <div className="id-pw">
                    <label className="label-l">사업자번호</label>
                    <FormControlLabel
                      control={
                        <Input
                          placeholder="10자리 숫자를 입력해 주세요"
                          value={corporationIdentificationNo}
                          onChange={(e) => setCorporationIdentificationNo(e.target.value)}
                          inputProps={{
                            sign: "on",
                            maxLength: 10,
                          }}
                          inputRef={inputCorporationIdentificationNo}
                          name="identificationNo"
                          data-testid="test-corporationIdentificationNo"
                          className="input-sytle"
                          onKeyUp={handleSearchKeyUp}
                        />
                      }
                      label=""
                    />
                  </div>
                  <div className="certificateLogin">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="corporationIntgtLoginIdCheck"
                          color="primary"
                          checked={corporationIntgtLoginIdCheck}
                          onChange={(e) => setCorporationIntgtLoginIdCheck(e.target.checked)}
                          inputProps={{
                            "aria-label": "primary checkbox",
                            "data-testid": "check-new-account",
                          }}
                        />
                      }
                      label="아이디저장"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="corporationIntgtLoginIdentificationNoCheck"
                          color="primary"
                          checked={corporationIntgtLoginIdentificationNoCheck}
                          onChange={(e) => setCorporationIntgtLoginIdentificationNoCheck(e.target.checked)}
                          inputProps={{
                            "aria-label": "primary checkbox",
                            "data-testid": "check-new-account",
                          }}
                        />
                      }
                      label="사업자번호저장"
                    />
                  </div>

                  <div>
                    <input type="button" className="btn-login btn-triple" onClick={signCorporationSubmit} value="공동인증서 로그인" />
                    <input type="button" className="btn-login btn-triple" onClick={signCorporationFinCertSubmit} value="금융인증서 로그인" />
                    <input type="button" className="btn-findId btn-triple" onClick={() => setOpen(true)} value="아이디찾기" />
                  </div>
                </form>
              </div>
            </Grid>
          </Grid>

          <Modal open={open} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" data-testid="open-modal">
            <div
              className="paper"
              style={{
                width: "610px",
                minHeight: "340px",
                height: "400px",
              }}>
              <div className="inner" style={{ minHeight: "auto" }}>
                <div className="modal-top-area">
                  <Button className="fr btn-close" onClick={() => setOpen(false)} data-testid="close-modal">
                    {""}
                  </Button>
                </div>
                <h3>아이디 찾기</h3>

                <div className="inforbox">
                  <ul>
                    <li>
                      이용기관의 사업자등록번호와 업무담당자의 생년월일을 입력하시면
                      <br />
                      CMS에서 신청하신 아이디를 찾을 수 있습니다.
                    </li>
                  </ul>
                </div>
                <div className="table-top-area" />
                <Table>
                  {CM.cfnCompColGroup(["40%", "auto"])}
                  <TableBody>
                    <TableRow>
                      <TableCell className="th">사업자번호</TableCell>
                      <TableCell className="td">
                        <FormControlLabel
                          control={
                            <Input
                              placeholder="사업자등록번호를 입력해 주세요."
                              value={bizNo}
                              onChange={(e) => setBizNo(e.target.value)}
                              inputRef={inputBizNo}
                              name="bizNo"
                              data-testid="test-findId-bizNo"
                              className="input-sytle"
                            />
                          }
                          label=""
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="th">담당자 생년월일</TableCell>
                      <TableCell className="td">
                        <FormControlLabel
                          control={
                            <Input
                              placeholder="6자리 생년월일을 입력해주세요."
                              value={staffBirthDate}
                              onChange={(e) => setStaffBirthDate(e.target.value)}
                              inputRef={inputStaffBirthDate}
                              name="staffBirthDate"
                              data-testid="test-findId-staffBirthDate"
                              className="input-sytle"
                            />
                          }
                          label=""
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>

              <div className="table-bottom-area" style={{ textAlign: "center" }}>
                <div className="btn-l table-bottom-button" data-testid="test-move-findId" onClick={findId}>
                  아이디찾기
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
      <LoginFooter />
    </div>
  );
};

export default inject((rootStore) => ({
  loginStore: rootStore.loginStore,
}))(observer(CertificateLogin));
